@import './src/styles/variables.scss';
@charset 'utf-8';

.contact { //main
  padding: 54px 0;
  @media #{$small} {
    padding-bottom: 0;
  }
  .inner {
    max-width: 630px;
    margin: 0 auto;
  }
  h1 {
    margin: 27px 0 2px;
    font-size: 20px;
    color: #FF7C00;
    background: -webkit-linear-gradient(top, #e4e4e4 0%, #fbfafb 50%, #adadae 51%, #828282 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .frame {
    padding: 20px;
    border: 2px solid #f6dcb4;
    box-shadow: 2px 2px solid #333;
    background: url('../images/frame_bg.png') no-repeat center center;
    background-size: cover;
    @media #{$small} {
      padding: 16px;
    }
    .logos {
      margin: 0 0 25px;
      text-align: center;
      .logo {
        width: 100%;
        max-width: 131px;
      }
      .lod {
        max-width: 269px;
        margin: 0 auto;
      }
    }
    button {
      border: none;
      background: transparent;
      outline: none;
    }
    .links {
      margin-bottom: 42px;
      text-align: center;
      @media #{$small} {
        margin-bottom: 22px;
      }
      a {
        display: inline-block;
        margin-bottom: 1em;
        padding-bottom: 4px;
        color: #e30318;
        font-size: 16px;
        text-decoration: none;
        border-bottom: 1px solid #e30318;
        @media #{$small} {
          font-size: 14px;
        }
      }
    }
    .rep {
      margin-bottom: 10px;
      font-size: 14px;
      @media #{$small} {
        font-size: 13px;
      }
    }
  }
}

/* 特定商取引 関連ないけどここで */
.transaction { //main
  padding: 54px 0;
  @media #{$small} {
    padding-bottom: 0;
  }
  .inner {
    max-width: 630px;
    margin: 0 auto;
  }
  h1 {
    margin: 27px 0 2px;
    font-size: 20px;
    color: #FF7C00;
    background: -webkit-linear-gradient(top, #e4e4e4 0%, #fbfafb 50%, #adadae 51%, #828282 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .desc {
    line-height: 1.8;
    p {
      margin-bottom: 1em;
      font-size: 18px;
      @media #{$small} {
        font-size: 14px;
      }
    }
  }
  .frame {
    padding: 20px;
    border: 2px solid #f6dcb4;
    box-shadow: 2px 2px solid #333;
    background: url('../images/frame_bg.png') no-repeat center center;
    background-size: cover;
    .logos {
      margin: 0 0 25px;
      text-align: center;
      .logo {
        width: 100%;
        max-width: 131px;
      }
      .lod {
        max-width: 269px;
        margin: 0 auto;
      }
    }
  }
  .back_link {
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
  }
}


/*---- form ----*/
.input_group {
  margin-bottom: 18px;
  p {
    color: #bfbfbf;
    font-size: 16px;
    @media #{$small} {
      font-size: 14px;
    }
  }
  .input_row {
    display: flex;
    justify-content: space-between;
    > * {
      flex-basis: 48%;
    }
    &.pack {
      justify-content: flex-start;
      > * {
        flex-basis: auto;
        margin-right: 20px;
      }
    }
  }
  input {
    width: 100%;
    height: 45px;
    padding: 0 5px 0 9px;
    color: #303007;
    font-size: 16px;
    border: 1px solid #000;
    border-radius: 4px;
    background: #eee;
    box-shadow: 0 2.5px 4px 0 rgba(0,0,0,0.45) inset, 0px 0px 4px 1.5px #303007;
    outline: none;
    @media #{$small} {
      height: 40px;
      font-size: 14px;
    }
    &::placeholder {
      color: #a1a1a1;
    }
    &.err {
      border: 1px solid #e30318;
      background: #fce5e7;
    }
  }
  .select {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 60%;
      right: 15px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      background: url('../images/metal_arrow.png') no-repeat center center;
      background-size: 12px auto;
    }
  }
  select {
    width: 100%;
    height: 45px;
    padding: 0 13px;
    color: #303007;
    font-size: 16px;
    border: 1px solid #000;
    border: 1px solid #dadde1;
    border-radius: 4px;
    box-shadow: 0 2.5px 4px 0 rgba(0,0,0,0.45) inset;
    background: #eee;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    @media #{$small} {
      height: 40px;
      font-size: 14px;
    }
    &.err {
      border: 1px solid #e30318;
      background: #fce5e7;
    }
  }
  textarea {
    width: 100%;
    height: 130px;
    padding: 10px 13px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #000;
    background: #eee;
    box-shadow: 0 2.5px 4px 0 rgba(0,0,0,0.45) inset, 0px 0px 4px 1.5px #303007;
    outline: none;
    &.err {
      border: 1px solid #e30318;
      background: #fce5e7;
    }
  }
  legend {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #fff;
    font-size: 16px;
    @media #{$small} {
      font-size: 14px;
    }
    &.f_center {
      justify-content: center;
    }
    small {
      display: block;
      margin-left: 11px;
      font-size: 10px;
      color: #999;
    }
  }
  .legend {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    color: #fff;
    font-size: 16px;
    @media #{$small} {
      font-size: 14px;
    }
    &.f_center {
      justify-content: center;
    }
    small {
      display: block;
      margin-left: 11px;
      font-size: 10px;
      color: #999;
    }
  }
  .err_msg {
    display: block;
    margin-top: 5px;
    color: #e30318;
    font-size: 12px;
  }
  .checkbox {
    -webkit-tap-highlight-color: transparent;
    & + span {
      display: inline-block;
      line-height: 1;
      margin-left: 10px;
    }
    label {
      position: relative;
      cursor: pointer;
      input[type="checkbox"] {
        position: relative;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 0;
        box-shadow: none;
        border: none;
        background: transparent;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          width: 18px;
          height: 18px;
          background: url('../images/check_mark.png') no-repeat center center;
          background-size: 18px 18px;
          transition:transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
          transform: rotate(0) scale(0, 0);
          z-index: 1;
        }
        &:checked {
          &:before {
            transform: rotate(0) scale(1, 1);
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          width: 14px;
          height: 14px;
          border: 2px solid #bb9662;
          background: #333;
          border-radius: 2px;
        }
        @media only screen and (max-width: 370px) {
          &:after {
            top: -3px;
          }
          &:before {
            top: -3px;
          }
        }
      }
      .legend {
        @media #{$small} {
          font-size: 13px;
        }
        @media only screen and (max-width: 370px) {
          display: block;
        }
        .mandatory {
          @media only screen and (max-width: 370px) {
            margin: 5px 0 0 0;
          }
        }
      }
      .two_leader {
        padding-left: 1em;
        text-indent: -1.1em;
        @media only screen and (max-width: 370px) {
          padding: 0;
          text-indent: 0;
        }
      }
    }
  }
}

/*---- radio ----*/
.radio {
  display: block;
  line-height: 1;
  font-size: 12px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  input[type="radio"] {
    display: none;
    &:checked {
      & + i::before {
        // border: 2px solid $main_color;
      }
      & + i::after {
        opacity: 1;
      }
    }
  }
  i {
    position: relative;
    display: inline-block;
    line-height: 1;
    &:before {
      content: "";
      display: block;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid #bfbfbf;
      border-radius: 50%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      width: 9px;
      height: 9px;
      background: #bfbfbf;
      border-radius: 50%;
      opacity: 0;
      transition: 0.6s;
    }
  }
  .text {
    display: inline-block;
    line-height: 1.4;
    margin:0 20px 0 10px;
    vertical-align: top;
  }
}

.complete_msg {
  margin: 30px 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  @media #{$small} {
    font-size: 14px;
  }
}

.mandatory {
  display: block;
  margin-left: 10px;
  color: #e30318;
  font-size: 14px;
  @media #{$small} {
    font-size: 12px;
  }
}

/*---- button ----*/
.btn_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 25px 0 5px;
  img {
    width: 135px;
  }
  > button, > a {
    margin: 0;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.btn {
  display: inline-block;
  max-width: 269px;
  width: 100%;
  height: 56px;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  &.btn_primary {
    color: #fff;
    box-shadow: inset 0px -7px 0px 0px #621717;
  }
}