@import './src/styles/variables.scss';
@charset "UTF-8";

/*------------ clearfix ------------*/
.clearfix{
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

/*---- media ----*/
.pc_show {
  display: block !important;
  @media #{$small} {
    display: none !important;
  }
}
.pc_show-inline {
  display: inline-block !important;
  @media #{$small} {
    display: none !important;
  }
}
.pc_show_flex {
  display: flex !important;
  @media #{$small} {
    display: none !important;
  }
}
.sp_show {
  display: none !important;
  @media #{$small} {
    display: block !important;
  }
}
.sp_show-inline {
  display: none !important;
  @media #{$small} {
    display: inline-block !important;
  }
}
.sp_show_flex {
  display: none !important;
  @media #{$small} {
    display: flex !important;
  }
}

/*------------ block ------------*/
.d_block {
  display: block !important;
}
.d_inline {
  display: inline !important;
}
.d_inline_block {
  display: inline-block !important;
}
.d_flex {
  display: flex !important;
}
.d_inline_flex {
  display: inline-flex !important;
}
.d_grid {
  display: grid !important;
}
.d_inline_grid {
  display: inline-grid !important;
}
.d_none {
  display: none !important;
}

/*---- flex 拡張 ----*/
.flex_align_c {
  align-items: center !important;
}
// .flex_align_c_s {
//   align-items: center !important;
//   @media #{$sp} {
//     align-items: flex-start !important;
//   }
// }

/*------------ ブロック要素の中央揃え ------------*/
.d_block_center {
  margin: 0 auto !important;
}
.d_block_left {
  margin: 0 auto 0 0 !important;
}
.d_block_right {
  margin: 0 0 0 auto !important;
}

/*------------ flex ------------*/
.flex_a_fs {
  align-items: flex-start !important;
}
.flex_a_fe {
  align-items: flex-end !important;
}
.flex_a_c {
  align-items: center !important;
}
.flex_j_fs {
  justify-content: flex-start !important;
}
.flex_j_fe {
  justify-content: flex-end !important;
}
.flex_j_c {
  justify-content: center !important;
}

/*------------ width ------------*/
.w10 {
  width: 10% !important;
}
.w20 {
  width: 20% !important;
}
.w30 {
  width: 30% !important;
}
.w40 {
  width: 40% !important;
}
.w50 {
  width: 50% !important;
}
.w60 {
  width: 60% !important;
}
.w70 {
  width: 70% !important;
}
.w80 {
  width: 80% !important;
}
.w90 {
  width: 90% !important;
}
.w100 {
  width: 100% !important;
}
.w_auto {
  width: auto !important;
}

/*------------ min-width ------------*/
.min_w_auto {
  min-width: auto !important;
}
.min_w100 {
  min-width: 100% !important;
}

/*------------ max-width ------------*/
.max_w_auto {
  max-width: auto !important;
}
.max_w100 {
  max-width: 100% !important;
}

/*--------- height ---------*/
.h10 {
  height: 10% !important;
}
.h20 {
  height: 20% !important;
}
.h30 {
  height: 30% !important;
}
.h40 {
  height: 40% !important;
}
.h50 {
  height: 50% !important;
}
.h60 {
  height: 60% !important;
}
.h70 {
  height: 70% !important;
}
.h80 {
  height: 80% !important;
}
.h90 {
  height: 90% !important;
}
.h100 {
  height: 100% !important;
}
.h_auto {
  height: auto !important;
}

/*------------ min-height ------------*/
.min_h_auto {
  min-height: auto !important;
}
.min_h100 {
  min-height: 100% !important;
}

/*------------ max-height ------------*/
.max_h_auto {
  max-height: auto !important;
}
.max_h100 {
  max-height: 100% !important;
}

/*--------- line-height ---------*/
.lh1 {
  line-height: 1 !important;
}
.lh12 {
  line-height: 1.2 !important;
}
.lh13 {
  line-height: 1.3 !important;
}
.lh14 {
  line-height: 1.4 !important;
}
.lh15 {
  line-height: 1.5 !important;
}
.lh16 {
  line-height: 1.6 !important;
}
.lh17 {
  line-height: 1.7 !important;
}
.lh18 {
  line-height: 1.8 !important;
}
.lh19 {
  line-height: 1.9 !important;
}
.lh2 {
  line-height: 2 !important;
}
.lh25 {
  line-height: 2.5 !important;
}
.lh3 {
  line-height: 3 !important;
}
.lh35 {
  line-height: 3.5 !important;
}

/*--------- hr ---------*/
hr {
  display: block;
  width: 100%;
  border: none;
  &.hr_gray {
    margin: 24px 0;
    color: #333;
  }
  &.grada_line_top {
    position: relative;
    height: 15px;
    margin: 0;
    border-top: 1px solid #cfcfcf;
    &:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 10px;
      background-image: -moz-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(240,240,240) 100%);
      background-image: -webkit-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(240,240,240) 100%);
      background-image: -ms-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(240,240,240) 100%);
    }
    &.long {
      height: 48px;
      &:after {
        height: 43px;
      }
    }
  }
}

/*--------- text ---------*/
.underline {
  text-decoration: underline !important;
}
.underline_none {
  text-decoration: none !important;
}

/*--------- cursor ---------*/
.cusror_p {
  cursor: pointer !important;
}
.cursor_auto {
  cursor: auto !important;
}
.cursor_grab {
  cursor: grab !important;
}

/*--------- font-size ---------*/
.fs0 {
  font-size: 0px !important;
}
.fs10 {
  font-size: 10px !important;
}
.fs12 {
  font-size: 12px !important;
}
.fs13 {
  font-size: 13px !important;
}
.fs14 {
  font-size: 14px !important;
}
.fs15 {
  font-size: 15px !important;
}
.fs16 {
  font-size: 16px !important;
}
.fs18 {
  font-size: 18px !important;
}
.fs20 {
  font-size: 20px !important;
}
.fs22 {
  font-size: 22px !important;
}
.fs26 {
  font-size: 26px !important;
}
.fs32 {
  font-size: 32px !important;
}
.fs48 {
  font-size: 48px !important;
}
.fs56 {
  font-size: 56px !important;
}

/*------------ position ------------*/
.p_static {
  position: static !important;
}
.p_absolute {
  position: absolute !important;
}
.p_relative {
  position: relative !important;
}
.p_fixed {
  position: fixed !important;
}
.p_sticky {
  position: sticky !important;
}

/*------------ font-wight ------------*/
.f_normal {
  font-weight: normal !important;
}
.f_bold {
  font-weight: bold !important;
}

/*------------ vertical ------------*/
.v_top {
  vertical-align: top !important;
}
.v_middle {
  vertical-align: middle !important;
}
.v_bottom {
  vertical-align: bottom !important;
}
.v_tb {
  vertical-align: text-bottom !important;
}
.v_tt {
  vertical-align: text-top !important;
}
.v_sup {
  vertical-align: sup !important;
}
.v_sub {
  vertical-align: sub !important;
}

/*------------ white-space ------------*/
.ws_noraml {
  white-space: normal !important;
}
.ws_pre {
  white-space: pre !important;
}
.ws_nowrap {
  white-space: nowrap !important;
}

/*------------ border none ------------*/
.bt_none {
  border-top: none !important;
}
.br_none {
  border-right: none !important;
}
.bl_none {
  border-left: none !important;
}
.bb_none {
  border-bottom: none !important;
}

/*------------ opacity ------------*/
.opa_0 {
  opacity: 0 !important;
}
.opa_01 {
  opacity: 0.1 !important;
}
.opa_02 {
  opacity: 0.2 !important;
}
.opa_03 {
  opacity: 0.3 !important;
}
.opa_04 {
  opacity: 0.4 !important;
}
.opa_05 {
  opacity: 0.5 !important;
}
.opa_06 {
  opacity: 0.6 !important;
}
.opa_07 {
  opacity: 0.7 !important;
}
.opa_08 {
  opacity: 0.8 !important;
}
.opa_09 {
  opacity: 0.9 !important;
}
.opa_1 {
  opacity: 1 !important;
}

/*---- color ----*/
// .color_main {
//   color: $main_color !important;
// }
// .color_pink {
//   color: $color_pink !important;
// }
// .color_pink_dark {
//   color: $color_pink_dark !important;
// }

// .color_link_light {
//   color: $color_link_light !important;
// }
// .color_link_dark {
//   color: $color_link_dark !important;
// }

// .color_gold {
//   color: #534706 !important;
// }
// .color_error {
//   color: $color_error !important;
// }
// .color_alert {
//   color: $color_alert !important;
// }

.color_white {
  color: #fff !important;
}

/*
.color_main {
  color: $main_color !important;
}
.color_moderate_red {
  color: $color_moderate_red !important;
}
.color_error {
  color: $main_color !important;
}
*/

/*---- background-color ----*/
.bg_white {
  background-color: #fff !important;
}
.bg_black {
  background-color: #000 !important;
}

/* text-align */
.align_c {
  text-align: center !important;
}
.align_c_l {
  text-align: center !important;
  // @media #{$sp} {
  //   text-align: left !important;
  // }
}
.align_r {
  text-align: right !important;
}
.align_r_l {
  text-align: right !important;
  // @media #{$sp} {
  //   text-align: left !important;
  // }
}
.align_l {
  text-align: left !important;
}

/*---- 三点リーダー ----*/
.leader_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.leader_2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.leader_3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/*---- media ----*/
.media:first-child {
  margin-top: 0;
}
.media,.media-body {
  overflow: hidden;
  zoom: 1;
}
.media-body,.media-left,.media-right {
  display: table-cell;
  vertical-align: top;
}
.media-left {
  padding-right: 10px;
}
.media-body {
  width: 10000px;
}
.media-right {
  padding-left: 10px;
}

/*-- indent --*/
.indent08 {
  text-indent: -0.8em !important;
  padding-left: 0.8em !important;
}
.indent1 {
  text-indent: -1em !important;
  padding-left: 1em !important;
}
.indent13 {
  text-indent: -1.3em !important;
  padding-left: 1.3em !important;
}
.indent15 {
  text-indent: -1.5em !important;
  padding-left: 1.5em !important;
}
.indent2 {
  text-indent: -2em !important;
  padding-left: 2em !important;
}
.indent25 {
  text-indent: -2.5em !important;
  padding-left: 2.5em !important;
}
.indent26 {
  text-indent: -2.6em !important;
  padding-left: 2.6em !important;
}
.indent3 {
  text-indent: -3em !important;
  padding-left: 3em !important;
}
.indent33 {
  text-indent: -3.3em !important;
  padding-left: 3.3em !important;
}
.indent35 {
  text-indent: -3.5em !important;
  padding-left: 3.5em !important;
}
.indent4 {
  text-indent: -4em !important;
  padding-left: 4em !important;
}

/*---- letter-spacing ----*/
.ls_0 {
  letter-spacing: 0 !important;
}
.ls_m1 {
  letter-spacing: -1px !important;
}
.ls_m15 {
  letter-spacing: -1.5px !important;
}
.ls_m2 {
  letter-spacing: -2px !important;
}
.ls_m25 {
  letter-spacing: -2.5px !important;
}

/*------------ hidden show ------------*/
.show {
  display: block !important;
}
.hidden {
  display: none !important;
}

/*------------ margin padding ------------*/
/*mrgin*/
.mg0 {
  margin: 0 !important;
}
.mg10 {
  margin: 10px !important;
}
.mg20 {
  margin: 20px !important;
}
.mg30 {
  margin: 30px !important;
}
.mg40 {
  margin: 40px !important;
}
.mg50 {
  margin: 50px !important;
}
.mg60 {
  margin: 60px !important;
}
.mg70 {
  margin: 70px !important;
}
.mg80 {
  margin: 80px !important;
}
.mg90 {
  margin: 90px !important;
}
.mg100 {
  margin: 100px !important;
}

.mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.mtb15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.mtb16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mtb24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.mtb36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}
.mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.mtb64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

/*margin top*/
.mt0 {
  margin-top: 0 !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt12 {
  margin-top: 12px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt24 {
  margin-top: 24px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt32 {
  margin-top: 32px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt48 {
  margin-top: 48px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt56 {
  margin-top: 56px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt64 {
  margin-top: 64px !important;
}
.mt72 {
  margin-top: 72px !important;
}

/*margin-right*/
.mr0 {
  margin-right: 0 !important;
}
.mr4 {
  margin-right: 4px !important;
}
.mr8 {
  margin-right: 8px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr12 {
  margin-right: 12px !important;
}
.mr16 {
  margin-right: 16px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr24 {
  margin-right: 24px !important;
}
.mr32 {
  margin-right: 32px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mr48 {
  margin-right: 48px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mr56 {
  margin-right: 56px !important;
}
.mr60 {
  margin-right: 60px !important;
}
.mr64 {
  margin-right: 64px !important;
}
.mr72 {
  margin-right: 72px !important;
}

/*margin-left*/
.ml0 {
  margin-left: 0 !important;
}
.ml4 {
  margin-left: 4px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml12 {
  margin-left: 12px !important;
}
.ml16 {
  margin-left: 16px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml24 {
  margin-left: 24px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml32 {
  margin-left: 32px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml48 {
  margin-left: 48px !important;
}
.ml50 {
  margin-left: 50px !important;
}
.ml56 {
  margin-left: 56px !important;
}
.ml60 {
  margin-left: 60px !important;
}
.ml64 {
  margin-left: 64px !important;
}
.ml72 {
  margin-left: 72px !important;
}
.ml1em {
  margin-left: 1em !important;
}

/*margin-bottom*/
.mb0 {
  margin-bottom: 0 !important;
}
.mb4 {
  margin-bottom: 4px !important;
}
.mb8 {
  margin-bottom: 8px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb11 {
  margin-bottom: 11px !important;
}
.mb12 {
  margin-bottom: 12px !important;
}
.mb16 {
  margin-bottom: 16px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb24 {
  margin-bottom: 24px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb32 {
  margin-bottom: 32px !important;
}
.mb36 {
  margin-bottom: 36px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb48 {
  margin-bottom: 48px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb56 {
  margin-bottom: 56px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.mb64 {
  margin-bottom: 64px !important;
}
.mb72 {
  margin-bottom: 72px !important;
}

/*padding*/
.pd0 {
  padding: 0 !important;
}
.pd8 {
  padding: 8px !important;
}
.pd10 {
  padding: 10px !important;
}
.pd16 {
  padding: 16px !important;
}
.pd20 {
  padding: 20px !important;
}
.pd24 {
  padding: 24px !important;
}
.pd30 {
  padding: 30px !important;
}
.pd32 {
  padding: 32px !important;
}
.pd40 {
  padding: 40px !important;
}
.pd50 {
  padding: 50px !important;
}
.pd60 {
  padding: 60px !important;
}
.pd70 {
  padding: 70px !important;
}
.pd80 {
  padding: 80px !important;
}
.pd90 {
  padding: 90px !important;
}
.pd100 {
  padding: 100px !important;
}

/*padding-top*/
.pt0 {
  padding-top: 0 !important;
}
.pt4 {
  padding-top: 4px !important;
}
.pt8 {
  padding-top: 8px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt16 {
  padding-top: 16px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt32 {
  padding-top: 32px !important;
}
.pt40 {
  padding-top: 40px !important;
}
.pt48 {
  padding-top: 48px !important;
}
.pt50 {
  padding-top: 50px !important;
}
.pt56 {
  padding-top: 56px !important;
}
.pt60 {
  padding-top: 60px !important;
}
.pt64 {
  padding-top: 64px !important;
}
.pt72 {
  padding-top: 72px !important;
}

/*------------ padding-right ------------*/
.pr0 {
  padding-right: 0 !important;
}
.pr4 {
  padding-right: 4px !important;
}
.pr8 {
  padding-right: 8px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr12 {
  padding-right: 12px !important;
}
.pr16 {
  padding-right: 16px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pr24 {
  padding-right: 24px !important;
}
.pr30 {
  padding-right: 30px !important;
}
.pr32 {
  padding-right: 32px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pr48 {
  padding-right: 48px !important;
}
.pr50 {
  padding-right: 50px !important;
}
.pr56 {
  padding-right: 56px !important;
}
.pr60 {
  padding-right: 60px !important;
}
.pr64 {
  padding-right: 64px !important;
}
.pr72 {
  padding-right: 72px !important;
}

/*------------ padding-left ------------*/
.pl0 {
  padding-left: 0 !important;
}
.pl3 {
  padding-left: 3px !important;
}
.pl4 {
  padding-left: 4px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pl8 {
  padding-left: 8px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl12 {
  padding-left: 12px !important;
}
.pl16 {
  padding-left: 16px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pl24 {
  padding-left: 24px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl32 {
  padding-left: 32px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pl48 {
  padding-left: 48px !important;
}
.pl50 {
  padding-left: 50px !important;
}
.pl56 {
  padding-left: 56px !important;
}
.pl60 {
  padding-left: 60px !important;
}
.pl64 {
  padding-left: 64px !important;
}
.pl72 {
  padding-left: 72px !important;
}
.pl1em {
  padding-left: 1em !important;
}

/*------------ padding-bottom ------------*/
.pb0 {
  padding-bottom: 0 !important;
}
.pb4 {
  padding-bottom: 4px !important;
}
.pb8 {
  padding-bottom: 8px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb16 {
  padding-bottom: 16px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb24 {
  padding-bottom: 24px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.pb32 {
  padding-bottom: 32px !important;
}
.pb36 {
  padding-bottom: 36px !important;
}
.pb40 {
  padding-bottom: 40px !important;
}
.pb48 {
  padding-bottom: 48px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.pb56 {
  padding-bottom: 56px !important;
}
.pb60 {
  padding-bottom: 60px !important;
}
.pb64 {
  padding-bottom: 64px !important;
}
.pb72 {
  padding-bottom: 72px !important;
}

/*------------ object-fit IE対応する場合は別途jsプラグインが必要 以外はこれでOK ------------*/
.obj_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: middle;
  font-family: 'object-fit: cover; object-position: middle;';
  background-position: center center;
  &.long {
    object-fit: contain;
    font-family: 'object-fit: contain; object-position: middle;'
  }
}
.obj_img_contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: middle;
  font-family: 'object-fit: contain; object-position: middle;';
  background-position: center center;
}

/*------------ Grid ------------*/
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  &.top {
    align-items: flex-start;
  }
  &.middle {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.jf_center {
    justify-content: center;
  }
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col {
  position: relative;
  width: 100%;
  min-height: 1px;
}
.col {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}
.col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.333333%;
      -ms-flex: 0 0 8.333333%;
          flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.666667%;
      -ms-flex: 0 0 16.666667%;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.333333%;
      -ms-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.666667%;
      -ms-flex: 0 0 41.666667%;
          flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.333333%;
      -ms-flex: 0 0 58.333333%;
          flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.666667%;
      -ms-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.333333%;
      -ms-flex: 0 0 83.333333%;
          flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.666667%;
      -ms-flex: 0 0 91.666667%;
          flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}